// import { useAllBlockchain } from 'state/home/fetchAllBlockChain'

// import {useWeb3React} from '../../../packages/wagmi/src/useWeb3React'
// import TransactionBridge from './components/TransactionBridge'
// eslint-disable-next-line lodash/import-scope
import Faq from './components/Faq'
import Footer from './components/Footer'
import Governence from './components/Governence'
import HomeContainer from './components/Home'
import Security from './components/Security'
import * as Styles from './styles'

const Home = ({ pageSupportedChains }: { pageSupportedChains: number[] }) => {
  return (
    <Styles.StyledHome>
      <HomeContainer />
      <Governence />
      <Security />
      <Faq />
      <Footer />
    </Styles.StyledHome>
  )
}

export default Home
