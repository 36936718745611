import styled from 'styled-components'
import Image from 'next/image'
import FacebookIcon from './FacebookIcon'
import TelegramIcon from './TelegramIcon'
import TwitterIcon from './TwitterIcon'
import YoutubeIcon from './YoutubeIcon'

const FooterContainer = styled.div`
  background: #0c0c0b;
  z-index: 4;
  position: relative;
  margin-left: -200px;
  margin-right: -200px;
  padding-top: 60px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1025px) {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1100px;

  @media (max-width: 1025px) {
    flex-direction: column;
    // align-items: center;
    margin-left: 40px;
  }
`

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  // align-items: center;
  @media (max-width: 1025px) {
    margin-top: 40px;
  }
`

const FooterSection2 = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  @media (max-width: 1025px) {
    margin-top: 40px;
  }
`

const FooterTitle = styled.div`
  font-size: 20px;
  color: #fddf84;
  font-weight: 600;
  text-align: left;
  cursor: pointer;
`

const FooterItem = styled.a`
  margin-top: 12px;
  margin-bottom: 12px;
  cursor: pointer;
`

const SocialNetworks = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
`

const SocialIcon = styled.a`
  cursor: pointer;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #ffffff30;
  max-width: 1067.04px;
  margin-top: 42px;
`

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <div style={{ minWidth: 148, minHeight: 50, cursor: 'pointer' }}>
          <Image src="/images/logo/logo.png" alt="logo" width={148} height={50} />
        </div>
        <FooterSection>
          <FooterTitle>News</FooterTitle>
          <FooterItem>Press</FooterItem>
          <FooterItem>Blog</FooterItem>
          <FooterItem href="https://beurl.app/oIZrxejx" target="_blank" rel="noreferrer">
            Community experiences
          </FooterItem>
        </FooterSection>
        <FooterSection2>
          <FooterTitle style={{ marginBottom: 17 }}>
            Contact us: <span>Contact@tingfi.com</span>
          </FooterTitle>
          <FooterTitle style={{ marginBottom: 17 }}>Social Networks</FooterTitle>
          <SocialNetworks>
            <SocialIcon href="https://beurl.app/ZqpFzTCb" target="_blank" rel="noreferrer">
              <TwitterIcon />
            </SocialIcon>
            <SocialIcon href="https://beurl.app/vqohigZr" target="_blank" rel="noreferrer">
              <TelegramIcon />
            </SocialIcon>
            <SocialIcon href="https://beurl.app/aAfQvQNc" target="_blank" rel="noreferrer">
              <FacebookIcon />
            </SocialIcon>
            <SocialIcon href="https://beurl.app/ANSIYTIk" target="_blank" rel="noreferrer">
              <YoutubeIcon />
            </SocialIcon>
          </SocialNetworks>
        </FooterSection2>
      </FooterContent>
      <Divider />
    </FooterContainer>
  )
}

export default Footer
