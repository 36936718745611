import styled from 'styled-components'
import Image from 'next/image'
import { Element } from 'react-scroll'

const DotIcon = styled.div`
  width: 15px;
  height: 15px;
  background-color: #dec173;
  transform: rotate(45deg);
  margin-right: 10px;
`

const FaqContainer = styled.div`
  background: #151515;
  z-index: 4;
  position: relative;
  margin: 144px -200px 0;
  padding: 65px;

  @media (max-width: 1025px) {
    margin: 72px -20px 0;
    padding: 32px 20px;
  }
`

const FaqContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`

const FaqTitle = styled.div`
  text-align: center;
  color: #dec173;
  font-size: 52px;
  font-weight: 800;
`

const FaqBox = styled.div`
  border: 2px solid #dec173;
  border-radius: 14px;
  padding: 70px 45px;
  width: 100%;
  max-width: 1300px;
  display: flex;
  margin-top: 50px;

  @media (max-width: 1025px) {
    flex-direction: column;
    padding: 35px 22.5px;
  }
`

const FaqItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 26px;
  line-height: 50px;
`

const Faq = () => {
  return (
    <Element name="faq">
      <FaqContainer id="faq">
        <FaqContent>
          <FaqTitle>FAQ</FaqTitle>
          <FaqBox>
            <div data-aos="zoom-in-right" style={{ flex: 2 }}>
              <FaqItem>
                <DotIcon />
                What is TINGFI?
              </FaqItem>
              <FaqItem>
                <DotIcon />
                Why TINGFI?
              </FaqItem>
              <FaqItem>
                <DotIcon />
                Is there any risk?
              </FaqItem>
              <FaqItem>
                <DotIcon />
                Additional information and resources about DEFI
              </FaqItem>
            </div>
            <div data-aos="zoom-in-left" style={{ flex: 1, minWidth: 303, minHeight: 170 }}>
              <Image width={303.63} height={170.72} src="/images/home/qa.png" alt="qa" />
            </div>
          </FaqBox>
        </FaqContent>
      </FaqContainer>
    </Element>
  )
}

export default Faq
