import styled from 'styled-components'
import Image from 'next/image'
import { Button } from '@pancakeswap/uikit/src/components/Button'
import { Link, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll'

export const StyledButton = styled(Button)`
  background-image: linear-gradient(#fddf84, #d7a651);
  // width: 111.42px;
  border-radius: 4px;
`

export const CircleBlured = styled.div`
  width: 450px;
  height: 200px;
  background: #fef8b620;
  position: absolute;
  border-radius: 100px;
  z-index: 1;
  box-shadow: -200px 0px 400px 170px #fef8b620;
`

const GovernanceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  @media (max-width: 1025px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const RightContent = styled.div``

const Governence = () => {
  return (
    <Element name="governence">
      <div
        style={{
          background: '#151515',
          zIndex: 4,

          position: 'relative',
          // width: '100%',
          marginLeft: -200,
          marginRight: -200,
          marginTop: 144,
          paddingBottom: 50,
        }}
        id="governence"
      >
        <div
          style={{
            paddingTop: 65,
          }}
        >
          <div style={{ textAlign: 'center', color: '#DEC173', fontSize: 52, fontWeight: '800', marginBottom: 8 }}>
            Governence
          </div>
          <div style={{ textAlign: 'center', color: '#FFFFFF', fontSize: 20, fontWeight: '600', marginBottom: 20 }}>
            Governed by the community
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <GovernanceContainer>
              <div data-aos="fade-right" style={{ width: 415 }}>
                <div style={{ fontSize: 50, fontWeight: '700', textAlign: 'center' }}>
                  TINGFI is a fully decentralized,
                </div>
                <div style={{ fontSize: 26, lineHeight: '45px', textAlign: 'center' }}>
                  community governed protocol with{' '}
                </div>
                <div style={{ fontSize: 26, lineHeight: '45px', color: '#FDDF84', textAlign: 'center' }}>
                  100.000++ token holders.
                </div>
                <div style={{ textAlign: 'center' }}>
                  <StyledButton>
                    <div style={{ fontSize: 20, fontWeight: '600', color: '#000' }}>Governance Forum</div>
                  </StyledButton>
                </div>
              </div>
              <div
                data-aos="fade-up-left"
                style={{
                  minWidth: 300,
                  minHeight: 400,
                  marginTop: 40,
                }}
              >
                <Image width={385.94} height={415.56} src="/images/home/UDefi-Logo.png" alt="Governence" />
              </div>
            </GovernanceContainer>
          </div>
          <div style={{ position: 'absolute', right: 150, top: 200 }}>
            <CircleBlured />
          </div>
        </div>
      </div>
    </Element>
  )
}

export default Governence
