import styled from 'styled-components'
import CertikIcon from './CertikIcon'
import { CircleBlured } from './Home'
import SafuBadgeIcon from './SafuBadgeIcon'

const SecurityContainer = styled.div`
  background: #0b0b0b;
  z-index: 4;
  position: relative;
  margin-left: -200px;
  margin-right: -200px;
  padding-left: 300px;
  padding-right: 300px;

  @media (max-width: 1025px) {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const Content = styled.div`
  padding-top: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`

const Title = styled.div`
  text-align: center;
  color: #dec173;
  font-size: 52px;
  font-weight: 800;
  line-height: 63.39px;
`

const Subtitle = styled.div`
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
`

const Description = styled.div`
  text-align: center;
  color: #ffffff;
  font-size: 26px;
  font-weight: 600;
  width: 700px;
  margin-top: 44px;
  margin-bottom: 44px;

  @media (max-width: 1025px) {
    width: 100%;
  }
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1025px) {
    flex-direction: column;
  }
`

const IconBox = styled.div`
  border: 1px solid #ffffff;
  border-radius: 9px;
  height: 108.68px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 334.67px;

  &:first-child {
    margin-right: 88px;
  }

  @media (max-width: 1025px) {
    width: 334.67px;
    height: 108.68px;

    &:first-child {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
`

const Security = () => {
  return (
    <SecurityContainer id="security">
      <Content>
        <Title>Security</Title>
        <Subtitle>Security Contributors</Subtitle>
        <Description data-aos="fade-up">
          Audited by the world’s leading security firms, security of the TINGFI is the highest priority.
        </Description>
        <IconContainer>
          <IconBox data-aos="flip-up">
            <CertikIcon />
          </IconBox>
          <IconBox data-aos="flip-up">
            <SafuBadgeIcon />
          </IconBox>
        </IconContainer>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <CircleBlured style={{ position: 'absolute', bottom: -400 }} />
        </div>
      </Content>
    </SecurityContainer>
  )
}

export default Security
